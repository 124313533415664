import React, { useEffect, useState } from 'react'
import smoothscroll from 'smoothscroll-polyfill';
import { FaArrowUp } from "react-icons/fa6";

export default function BackToTop() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Polyfill for smooth scroll behavior
        smoothscroll.polyfill();

        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <div>
            {isVisible && (
                <button
                    onClick={scrollToTop}
                    className="back-to-top flex fixed text-lg rounded-full z-10 bottom-5 end-5 size-9 m-auto text-center bg-primary text-white justify-center items-center"
                >
                   <FaArrowUp />
                </button>
            )}
        </div>
    )
}
