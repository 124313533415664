export const InputText = ({
  label,
  name,
  placeholder,
  register,
  errors,
  options,
}) => {
  return (
    <div>
      {label && (
        <label htmlFor={name} className="text-[#555]">
          {label}
          {options?.required && <span className="text-red-500">*</span>}
        </label>
      )}

      <input
        className="w-full mt-3 p-2 outline-none border border-black"
        name={name}
        {...register(name, options)}
        id={name}
        placeholder={placeholder}
        type="text"
      />
      {errors[name] && (
        <p className="mt-1 text-red-500 text-sm">{errors[name].message}</p>
      )}
    </div>
  );
};
