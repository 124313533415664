import afterHoursImage from "@/assets/images/Afterhours.png";
import avMarketImage from "@/assets/images/AVMARKET.png";
import fur4Image from "@/assets/images/Fur4.png";
import usAutomation from "@/assets/images/US_AUTOMATION.png";
import { PrimaryButton } from "@/util/Button/PrimaryButton";
import TtbGrossistImage from "@/assets/images/Grossist.png";
import gghydImage from "@/assets/images/gghyd.png";
import { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import { AnimatePresence, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
const projects = [
  {
    id: 1,
    image: afterHoursImage,
    category: "E-commerce Development",
    title: "After Hours Creative Studio",
    description: `I recently undertook a comprehensive upgrade of the 'After Hours Creative Studio' website from Magento 2.2.10 to the latest version, Magento 2.4.7. This project involved more than just a straightforward update—it required meticulous attention to detail and a deep understanding of the platform architecture.

<p style="font-weight: bold; font-size: 18px; color:black">Key Highlights:</p>

- Seamless Upgrade Process: Managed the upgrade process from start to finish, ensuring minimal downtime.
- Module Compatibility: Conducted extensive testing to ensure all modules were fully compatible with Magento 2.4.7.
- Issue Identification and Resolution: Fixed issues in the search bar, cart page, filter functionality, and mini cart.
- Quality Assurance: Performed rigorous QA testing to ensure cross-browser compatibility, mobile responsiveness, and optimal performance.

<p style="font-weight: bold; font-size: 18px; color:black">Outcome:</p> The upgrade resulted in a more secure, faster, and user-friendly website, improving the overall customer experience and setting the stage for future enhancements.`,
    link: "https://www.afterhourscreativestudio.com/",
  },
  {
    id: 2,
    image: avMarketImage,
    category: "Website Restoration",
    title: "AV Market",
    description: `In my role as a web developer for the "AV Market" website, I tackled complex challenges that required both technical expertise and a deep understanding of Magento 2.

<p style="font-weight: bold; font-size: 18px; color:black">Key Highlights:</p>

- Custom Product Restoration: Faced with a situation where the client had inadvertently deleted products from the website, I developed a custom script to address the issue. This script compared the backup files with the current export files, identified the missing products, and converted the data into the correct Magento 2 CSV format. This precise approach ensured that all missing products were accurately restored to the site without any data loss or duplication.
- Website Update and Feature Enhancement: Successfully updated the AV Market website to the latest version, incorporating new security patches and performance improvements.
- Enhanced Validation Features: Implemented enhanced validation features on the checkout page, significantly reducing errors and improving the overall user experience during the purchasing process.
- Ongoing Maintenance and Support: Provide ongoing monthly maintenance for the site, including regular updates, performance optimizations, and security audits. This continuous support ensures that the website remains in peak condition and is always ready to meet customer demands.

<p style="font-weight: bold; font-size: 18px; color:black">Outcome:</p> My custom solution for product restoration, coupled with the successful update and enhancement of the website, has strengthened the AV Market’s online presence. The ongoing maintenance I provide ensures that the site is secure, efficient, and user-friendly, allowing the business to thrive.`,
    link: "https://www.avmarket.rs/",
  },
  {
    id: 3,
    image: gghydImage,
    category: "Feature Enhancement",
    title: "GG Hydraulics",
    description: `For the "GG Hydraulics" website, I played a key role in enhancing its functionality and ensuring its smooth operation. I applied critical patches using Composer, which helped to maintain the security and performance of the site. Additionally, I developed a custom shipping method tailored specifically for international orders, allowing the website to cater more effectively to global customers.`,
    link: "https://www.gghyd.com/",
  },
  {
    id: 4,
    image: fur4Image,
    category: "Full-Stack Development",
    title: "Fur4",
    description: `I fully developed the "Fur4 website" from scratch, integrating a React-based frontend with a Magento backend. I implemented several key features, including B2B and B2C functionality, and utilized GraphQL to create dynamic functionality on the contact page and other areas of the site.

<p style="font-weight: bold; font-size: 18px; color:black">Key Highlights:</p>
- Region-Specific Settings: Added region-specific settings for different countries, allowing for customized experiences.
- FAQ Management: Developed a comprehensive FAQ page that allows the admin to manage content easily.
- Catalog Integration: Connected the product catalog with Magento, ShipBOB, and Amazon Seller for streamlined inventory and order management.

<p style="font-weight: bold; font-size: 18px; color:black">Outcome:</p> The site has become a robust, scalable platform, effectively supporting both B2B and B2C customer bases while providing seamless inventory management across multiple channels.`,
    link: "https://dev.fur4.com/",
  },
  {
    id: 5,
    image: usAutomation,
    category: "Performance Optimization",
    title: "US Automations Controls",
    description: `For the "US Automations Controls" website, I enhanced performance by optimizing speed for faster load times and improved user experience. I used the Amaste extension to efficiently import products and resolved various bugs to ensure stable functionality. Additionally, I provided ongoing maintenance to keep the site secure, up-to-date, and running smoothly.`,
    link: "https://usac123.com/",
  },
  {
    id: 6,
    image: TtbGrossistImage,
    category: "E-commerce Optimization",
    title: "TTBGrossist",
    description: `For the TTBGrossist website, we focused on enhancing overall performance and ensuring long-term stability and security.

<p style="font-weight: bold; font-size: 18px; color:black">Key Contributions:</p>
 <p style="font-weight: bold; font-size: 18px; color:black">Performance Optimization:</p> Optimized the website’s speed to achieve faster load times, significantly improving user experience and engagement. This optimization involved fine-tuning various aspects of the site to ensure smooth and responsive performance.
<p style="font-weight: bold; font-size: 18px; color:black">Efficient Product Import with Amaste Extension:</p> Utilized the Amaste extension to streamline the process of importing products, ensuring that the product catalog was updated efficiently and accurately. This allowed for a more dynamic and up-to-date inventory management system.
<p style="font-weight: bold; font-size: 18px; color:black">Bug Resolution:</p> Identified and resolved various bugs across the site, ensuring stable and reliable functionality. These fixes were critical in maintaining a smooth user experience and preventing potential disruptions.
<p style="font-weight: bold; font-size: 18px; color:black">Ongoing Maintenance:</p> Provided continuous maintenance services to keep the site secure, up-to-date, and operating efficiently. This included regular updates, security patches, and performance monitoring to ensure the website’s long-term success.

<p style="font-weight: bold; font-size: 18px; color:black">Outcome:</p> The enhancements made to the TTBGrossist website resulted in a faster, more reliable, and user-friendly platform. The combination of performance optimization, efficient product management, and ongoing maintenance has ensured the site remains secure and fully functional, providing a superior experience for users.`,
    link: "https://www.ttbgrossist.com/?t=truck",
  },
];

const ProjectItem = ({ project, isExpanded, toggleDescription }) => {
  const shouldTruncate = project.description.length > 500;

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  return (
    <div
      className={`flex flex-col lg:flex-row gap-8 ${
        project.id % 2 !== 0 ? "lg:flex-row-reverse" : ""
      }`}
      ref={ref}
    >
      <motion.div
        className="lg:w-1/2"
        initial={{ opacity: 0, y: 100 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1.0, ease: "easeOut" }}
      >
        <div className="bg-black p-4 rounded-lg shadow-lg">
          <img
            src={project.image}
            alt={project.title}
            className="w-full h-auto object-cover rounded hover:scale-95 duration-500"
          />
        </div>
      </motion.div>

      <motion.div
        className="lg:w-1/2 flex flex-col justify-center"
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}
      >
        <div className="w-[140px] mb-5 p-1.5 text-xs uppercase leading-tight text-center tracking-widest rounded-full bg-[#ff651c33]">
          <h3>{project.category}</h3>
        </div>
        <h3 className="text-2xl font-bold mb-4">{project.title}</h3>
        <p className="text-gray-600 mb-6">
          <AnimatePresence initial={false}>
            <motion.div
              key={isExpanded ? "expanded" : "collapsed"}
              initial="collapsed"
              animate="expanded"
              variants={{
                expanded: { height: "auto", opacity: 1 },
                collapsed: {
                  height: shouldTruncate ? "200px" : "auto",
                  opacity: 1,
                },
              }}
              transition={{ duration: 0.4, ease: "easeInOut" }}
              className="relative overflow-hidden"
            >
              <div
                className="text-gray-600 mb-6"
                dangerouslySetInnerHTML={{
                  __html: isExpanded
                    ? project.description
                    : shouldTruncate
                    ? `${project.description.substring(0, 500)}...`
                    : project.description,
                }}
              />
              {!isExpanded && shouldTruncate && (
                <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-[#fef6e9] to-transparent" />
              )}
            </motion.div>
          </AnimatePresence>
          {project.description.length > 500 && (
            <span>
              <button
                onClick={() => toggleDescription(project.id)}
                className="ml-2 text-blue-500 font-semibold inline-flex items-center"
              >
                {isExpanded ? (
                  <>
                    Show Less <ChevronUp className="ml-1 h-4 w-4" />
                  </>
                ) : (
                  <>
                    Show More <ChevronDown className="ml-1 h-4 w-4" />
                  </>
                )}
              </button>
            </span>
          )}
        </p>
        <PrimaryButton title="View Project" project={project} />
      </motion.div>
    </div>
  );
};

export const LatestProject = () => {
  const [expandedProject, setExpandedProject] = useState(null);

  const toggleDescription = (id) => {
    setExpandedProject(expandedProject === id ? null : id);
  };
  return (
    <section
      id="portfolio"
      className="bg-[#fef6e9] md:py-[78px] py-[75px] cursor-pointer"
    >
      <div className="group container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">
          Latest Projects
        </h2>
        <div className="space-y-16">
          {projects.map((project, index) => (
            <ProjectItem
              key={project.id}
              project={project}
              isExpanded={expandedProject === project.id}
              toggleDescription={toggleDescription}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
