import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { LaravelSvg, MagentoSvg, NodeJsSvg, ReactSvg, ShopifySvg, WordPressSvg } from "@/components/Svg/index";
import "swiper/css";
export const LogoSection = () => {
  const LogoSectionData = [
    {
      svg: <LaravelSvg/>,
      id: 1,
    },
    {
      svg: <MagentoSvg/>,
      id: 2,
    },
    {
      svg: <WordPressSvg/>,
      id: 3,
    },
    {
      svg: <ShopifySvg/>,
      id: 4,
    },
    {
      svg:<NodeJsSvg/>,
      id: 5,
    },
    {
      svg: <ReactSvg/>,
      id: 6,
    },
  ];

  return (
    <div className="bg-[#5B5B5B] py-8 px-7">
      <div className="container !m-auto">
      <Swiper
        loop={true}
        breakpoints={{
          640: { slidesPerView: 2 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 5 },
          320: { slidesPerView: 2 },
        }}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
      }}
        modules={[Pagination,Autoplay ]}
        className="w-full h-full"
      >
        {LogoSectionData.length > 0 &&
          LogoSectionData.map((item) => (
            <SwiperSlide key={item.id}>
              <div className="flex justify-center items-center">
                {item?.svg}
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
      </div>
    </div>
  );
};
