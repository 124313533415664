import RotatingIcons from "@/components/Common/OrbitAnimation/OrbitAnimation";


export const HomeBanner = () => {
  return (
    <>
      <div id="home" className="bg-[#fec4481a] pb-20">
        <div className="mx-3 xl:mx-28 xl:pt-10 lg:grid container !m-auto lg:grid-cols-2 lg:gap-28 md:items-center items-left">
          <div className="pt-12 sm:pt-24 p-3 text-left">
            <h6 className="mb-2 text-base leading-tight uppercase tracking-widest font-semibold text-[#333]">
              Welcome to BronzeByte IT Solutions
            </h6>

            <h1
              id="output"
              className="mb-3 leading-tight text-base md:text-[45px] sm:text-[60px] font-semibold text-[#222]"
            >
              Transforming Ideas into Digital Reality
            </h1>

            <div className="pb-1 font-normal text-[18px] sm:text-[20px] text-[#666]">
              <p>
                At BronzeByte, we build high-performance websites and web
                applications designed to engage and convert. Our team brings
                expertise in full-stack web development to create solutions that
                empower your business to thrive online.
              </p>
              <p className="mt-4">
                With a focus on responsive design, seamless navigation, and
                optimized performance, we leverage technologies like HTML, CSS,
                JavaScript, React JS, and Next JS to deliver an exceptional
                digital experience for your customers.
              </p>
            </div>

            <div className="flex justify-center lg:flex lg:justify-start">
              <a
                href="#services"
                className="shadow-[5px_5px] hover:shadow-[3px_3px] flex justify-center mt-5 px-5 py-2 text-center font-semibold tracking-widest text-sm shadow-secondary hover:shadow-[#1a73e8] bg-[#1a73e8] hover:bg-secondary border border-[#1a73e8] hover:border-secondary text-white uppercase w-[160px]"
              >
                Explore Services
              </a>
            </div>
          </div>

          <div className="mt-16">
            {/* <img className="rounded-lg shadow-lg" src={homeBannerImage} alt="Web development illustration" /> */}
            <RotatingIcons />
          </div>
        </div>
      </div>
    </>
  );
};
