import { Footer } from "@/components/Common/Footer/Footer"
import { Header } from "@/components/Common/Header/header"

export const Layout=({children})=>{
    return(
        <>
        <Header/>
        {children}
        <Footer/>
        </>
    )
}