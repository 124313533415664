import { useDispatch, useSelector } from "react-redux";
import Drawer from "@/components/Common/Drawer/Drawer";
import { closeDrawer, openDrawer } from "@/lib/redux/slices/drawerSlice";

import { IoMdMenu } from "react-icons/io";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

export const Header = () => {
  const dispatch = useDispatch();
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeItem, setActiveItem] = useState("home");

  const { isOpen } = useSelector((state) => state.drawer);

  const handleOpenDrawer = () => {
    dispatch(openDrawer({ type: "navbar" }));
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
    updateActiveItem();
  };

  const getTitle = () => {
    switch (activeItem) {
      case "home":
        return "BronzeByte IT Solutions";
      case "services":
        return "Our Services";
      case "skills":
        return "Our Skills";
      case "portfolio":
        return "Our Portfolio";
      case "contact":
        return "Contact Us";
      default:
        return "BronzeByte IT Solutions";
    }
  };

  const updateActiveItem = () => {
    const sections = ["home", "services", "skills", "portfolio", "contact"];
    let newActiveItem = "home";
    sections.forEach((sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        const { top } = section.getBoundingClientRect();
        if (top <= window.innerHeight / 2) {
          newActiveItem = sectionId;
        }
      }
    });
    setActiveItem(newActiveItem);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    updateActiveItem();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCloseDrawer = () => {
    dispatch(closeDrawer());
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
    const section = document.getElementById(item);
    if (section) {
      const headerOffset = 100; // Adjust this value based on your header height
      const elementPosition = section.getBoundingClientRect().top; // Position relative to the viewport
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset; // Calculate the final position to scroll to
  
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };
  

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "BronzeByte IT Solutions",
    url: "https://bronzebyte.com/",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+91 7710113366",
      contactType: "Customer Service",
      areaServed: "Global",
      availableLanguage: "English",
    },
    sameAs: ["https://www.linkedin.com/company/bronzebyte-it-solutions"],
  };

  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
        <meta
          name="description"
          content="Explore our services, skills, portfolio, and contact information."
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div
        
        className={`${
          isScrolled ? "bg-white" : "!bg-[#fec4481a]"
        } sticky top-0 z-50 transition-colors duration-150 ease-in-out`}
      >
        <div className="xl:mx-28 xl:px-0 px-3 container !m-auto py-5 flex justify-between lg:flex lg:justify-between">
          <div className="logo font-bold md:text-2xl">
            <a href="home">BronzeByte IT Solutions</a>
          </div>

          <div className="hidden lg:block flex justify-between">
            <div className="px-2 tracking-widest font-semibold   flex items-center justify-between gap-6 uppercase">
              {["home", "services", "skills", "portfolio", "contact"].map(
                (item) => (
                  <a
                    href={`#${item}`}
                    key={item}
                    className={`cursor-pointer text-sm leading-9 ${
                      activeItem === item
                        ? "text-primary border-b-2 border-primary"
                        : "hover:text-primary hover:border-b-2 hover:border-primary"
                    }`}
                    onClick={() => handleItemClick(item)}
                  >
                    {item.charAt(0).toUpperCase() + item.slice(1)}
                  </a>
                )
              )}
            </div>
          </div>

          <a
            href="#contact"
            className="hidden lg:block shadow-[5px_5px] hover:shadow-[3px_3px] shadow-secondary hover:shadow-primary font-semibold uppercase bg-primary hover:bg-secondary px-5 py-2 tracking-widest text-sm text-white border border-primary hover:border-secondary"
          >
            Contact Now
          </a>

          <div className="lg:hidden">
            <div className="menu text-[35px]" onClick={handleOpenDrawer}>
              <IoMdMenu />
            </div>
            {isOpen && (
              <Drawer>
                <div className="sidebar bg-white rounded-lg w-[40%] h-auto lg:hidden p-4">
                  <ul
                    className="text-[24px] space-y-3 text-start"
                    onClick={() => handleCloseDrawer()}
                  >
                    {["home", "services", "skills", "portfolio", "contact"].map(
                      (item) => (
                        <li
                          key={item}
                          className={`cursor-pointer text-sm ${
                            activeItem === item
                              ? "text-primary"
                              : ""
                          }`}
                          onClick={() => handleItemClick(item)}
                        >
                          <a href={`#${item}`}>
                            {item.charAt(0).toUpperCase() + item.slice(1)}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </Drawer>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
