"use client";
import { closeDrawer } from "@/lib/redux/slices/drawerSlice";

import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "lucide-react";
import { useEffect } from "react";

export default function Drawer({ children }) {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.drawer);
  const handleCloseDrawer = () => {
    dispatch(closeDrawer());
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <>
      <AnimatePresence>
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black z-30"
            onClick={handleCloseDrawer}
          />
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ type: "spring", stiffness: 500, damping: 50 }}
            className="fixed top-0 right-0 h-full w-full md:w-80 bg-white shadow-lg z-40"
          >
            {isOpen ? (
              <div
                className="relative z-10"
                aria-labelledby="slide-over-title"
                role="dialog"
                aria-modal="true"
              >
                <div
                  className="fixed inset-0 transition-opacity"
                  aria-hidden="true"
                ></div>

                <div className="fixed inset-0">
                  <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                      <div className="pointer-events-auto w-full max-w-md">
                        <div className="flex h-screen flex-col w-full z-10 p-2">
                          <div className="relative flex-1">
                            <div
                              className="fixed inset-0 h-screen bg-black/30 w-full"
                              aria-hidden="true"
                              onClick={handleCloseDrawer}
                            ></div>

                            <div
                              className={`fixed top-0 right-0 bg-white p-4 max-h-screen h-screen md:max-w-[450px] md:min-w-[450px] w-full flex shadow-lg transition-transform duration-300 ease-in-out`}
                            >
                              <header className="absolute top-0 z-10 flex w-full max-w-md items-center justify-between border-b border-border-200 border-opacity-75 bg-light px-6 py-4">
                                <button
                                  className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-100 text-muted transition-all duration-200 hover:bg-accent hover:text-light focus:bg-accent focus:text-light focus:outline-0 "
                                  onClick={handleCloseDrawer}
                                >
                                  <span className="sr-only">close</span>
                                  <X />
                                </button>
                              </header>

                              <div className="mt-10 flex-grow py-2 mr-2 ">
                                {children}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </motion.div>
        </>
      </AnimatePresence>
    </>
  );
}
