export const PrimaryButton = ({ title, project }) => {
    return (
        <a
            href={project.link}
            rel="noopener noreferrer"
            target="_blank"
            className="w-[140px] mt-6 cursor-pointer text-xs text-center text-white tracking-widest py-2 uppercase font-medium bg-primary shadow-[5px_5px] shadow-secondary hover:shadow-[3px_3px] hover:shadow-[#ff651c] hover:bg-secondary hover:border-secondary"
        >
            {title}
        </a>
    );
};
