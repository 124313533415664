import React from 'react';
import { FaLaravel, FaMagento, FaReact, FaShopify, FaWordpress } from 'react-icons/fa';

const icons = [
  { name: <FaLaravel size={24} />, color: 'from-red-500 to-red-700' },
  { name: <FaMagento size={24} />, color: 'from-orange-500 to-orange-700' },
  { name: <FaReact size={24} />, color: 'from-blue-400 to-blue-600' },
  { name: <FaShopify size={24} />, color: 'from-green-500 to-green-700' },
  { name: <FaWordpress size={24} />, color: 'from-indigo-400 to-indigo-600' },
];

export default function RotatingIcons() {
  return (
    <div className="relative flex items-center justify-center md:w-[400px] h-[400px] mx-auto ">
      <div className="absolute w-20 h-20 bg-gradient-to-br from-purple-500 to-pink-500 rounded-full flex items-center justify-center text-white text-2xl font-bold shadow-lg z-20">
        Web
      </div>

      <div className="relative w-full h-full md:animate-[spin_20s_linear_infinite]">
        {icons.map((icon, index) => (
          <div
            key={index}
            className={`absolute w-16 h-16 flex items-center justify-center rounded-full text-white bg-gradient-to-br ${icon.color} transition-all duration-300 ease-in-out hover:scale-110 hover:shadow-xl shadow-lg`}
            style={{
              top: `40%`,
              left: `40%`,
              transform: `rotate(${(index * (360 / icons.length))}deg) translate(160px) rotate(-${(index * (360 / icons.length))}deg)`,
            }}
          >
            {icon.name}
          </div>
        ))}
      </div>

      <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-pink-500/20 rounded-full filter blur-xl opacity-50"></div>
    </div>
  );
}
