"use client";

import drawerReducer from "@/lib/redux/slices/drawerSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  drawer: drawerReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
