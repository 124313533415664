import React from 'react';
import { motion, useInView } from 'framer-motion';
import { Smartphone, Laptop, ShoppingCart, Layout, Pen } from 'lucide-react';
import { CgWebsite } from "react-icons/cg";
import 'font-awesome/css/font-awesome.min.css';

const services = [
    {
        title: "Responsive Web Design",
        description: "Crafting visually stunning, mobile-friendly websites that deliver a seamless user experience across all devices.",
        icon: <Smartphone className="w-8 h-8" />,
        bgColor: "bg-[#9bd3d0]"
    },
    {
        title: "E-commerce Solutions",
        description: "Developing scalable e-commerce platforms with secure payment integrations and easy-to-navigate interfaces.",
        icon: <Laptop className="w-8 h-8" />,
        bgColor: "bg-[#fec447]"
    },
    {
        title: "CMS Development",
        description: "Building custom content management systems (CMS) for easy content updates and management.",
        icon: <ShoppingCart className="w-8 h-8" />,
        bgColor: "bg-[#feccb5]"
    },
    {
        title: "Web Application Development",
        description: "Creating dynamic, robust, and interactive web applications that enhance user engagement and streamline processes.",
        icon: <Layout className="w-8 h-8" />,
        bgColor: "bg-[#92b5ff]"
    },
    {
        title: "UI/UX Design",
        description: "Designing intuitive user interfaces (UI) with exceptional user experiences (UX) to engage visitors.",
        icon: <Pen className="w-8 h-8" />,
        bgColor: "bg-[#c4d39b]"
    },
    {
        title: "Website Maintenance & Support",
        description: "Providing ongoing support, updates, and maintenance to ensure your website is always performing at its best.",
        icon: <CgWebsite className="w-8 h-8" />,
        bgColor: "bg-[#d2ceff]"
    }
];

const ServiceCard = ({ service }) => {
  const ref = React.useRef(null);
  const inView = useInView(ref, { triggerOnce: false, threshold: 0.1 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <div
        className={`${service.bgColor} p-6 cursor-pointer rounded-lg shadow-lg flex items-start space-x-4 transition-all duration-300 hover:shadow-xl`}
      >
        <div className="bg-white p-3 rounded-lg">
          {service.icon}
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
          <p className="text-gray-700">{service.description}</p>
        </div>
      </div>
    </motion.div>
  );
};

export const OurServices = () => {
  return (
    <section className="md:py-[78px] py-[75px] bg-[#f8f8f8]" id="services">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">Our Services</h2>
        <div className="grid md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} service={service} />
          ))}
        </div>
      </div>
    </section>
  );
};
