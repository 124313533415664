import React from 'react';
import { motion, useInView } from 'framer-motion';
import { IoLogoNodejs } from "react-icons/io";
import skillImage from "@/assets/images/modern-equipped-computer-lab.jpg";
import { FaLaravel, FaMagento, FaReact, FaShopify, FaWordpress } from "react-icons/fa";

const skills = [
  {
    name: "Laravel",
    icon: <FaLaravel className="w-[40px] h-[49px] py-2" />,
    bgColor: "#9bd3d0",
  },
  {
    name: "Magento",
    icon: <FaMagento className="w-[40px] h-[49px] py-2" />,
    bgColor: "#fec447",
  },
  {
    name: "React JS",
    icon: <FaReact className="w-[40px] h-[49px] py-2" />,
    bgColor: "#feccb5",
  },
  {
    name: "Wordpress",
    icon: <FaWordpress className="w-[40px] h-[49px] py-2" />,
    bgColor: "#92b5ff",
  },
  {
    name: "Shopify",
    icon: <FaShopify className="w-[40px] h-[49px] py-2" />,
    bgColor: "#c4d39b",
  },
  {
    name: "NodeJs",
    icon: <IoLogoNodejs className="w-[40px] h-[49px] py-2" />,
    bgColor: "#c4d39b",
  },
];

const SkillCard = ({ skill }) => {
  const ref = React.useRef(null);
  const inView = useInView(ref, { triggerOnce: false, threshold: 0.1 });

  return (
    <motion.div
      ref={ref}
      className={`p-2 xl:p-4 flex items-center bg-[${skill.bgColor}] hover:shadow-[3px_3px] hover:shadow-[#373737] border border-black`}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <div className="px-2 bg-white border border-black">
        {skill.icon}
      </div>
      <div className="md:pl-3 pl-[2.4px] tracking-wide text-base">
        <h6>{skill.name}</h6>
      </div>
    </motion.div>
  );
};

export const OurSkills = () => {
  const imgRef = React.useRef(null);
  const imgInView = useInView(imgRef, { triggerOnce: false, threshold: 0.1 });

  return (
    <>
      <div id="skills">
        <div className="xl:mx-28 container !m-auto lg:grid grid-cols-2 md:py-[78px] py-[75px] px-3 xl:px-0">
          <div className="gap-6 cursor-pointer">
            <div className="text-[38px] lg:text-[50px] pb-8 tracking-widest font-semibold uppercase">
              <h3> Our Skills </h3>
            </div>

            <div className="flex grid grid-cols-2 sm:grid sm:grid-cols-3 lg:grid lg:grid-cols-2 gap-6 justify-between">
              {skills.map((skill, index) => (
                <SkillCard key={index} skill={skill} />
              ))}
            </div>
          </div>

          <motion.div
            ref={imgRef}
            className="p-5"
            initial={{ opacity: 0, y: 100 }}
            animate={imgInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6, ease: "easeOut" }}
          >
            <img
              className="w-[100%] cursor-pointer hover:scale-95 hover:shadow-lg hover:shadow-[#373737] transition-all duration-300 ease-in-out"
              src={skillImage}
              alt="SkillImage"
            />
          </motion.div>
        </div>
      </div>
    </>
  );
};
