export const TextArea = ({
    label,
    placeholder,
    name,
    register,
    errors,
    options,
  }) => {
    return (
      <div className="mt-5">
        {label && (
          <label htmlFor="message" className="text-[#555]">
            {label}
            {options?.required && <span className="text-red-500">*</span>}
          </label>
        )}
        
        <textarea
          className="w-full mt-3 p-2 outline-none border border-black"
          name={name}
          {...register(name, options)}
          id="message"
          placeholder={placeholder}
          rows="4"
        ></textarea>
        {errors[name] && (
          <p className="mt-1 text-red-500 text-sm">{errors[name].message}</p>
        )}
      </div>
    );
  };
  