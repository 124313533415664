import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import React from "react";
import { motion, useInView } from "framer-motion";

export const TestiMonial = () => {
  const ref = React.useRef(null);
  const inView = useInView(ref, { triggerOnce: false, threshold: 0.1 });

  const testimonialData = [
    {
      imageUrl: "https://uiocean.com/themeforest/react/krinky/img/avatar-1.jpg",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      name: "Jennifer Lutheran",
      designation: "CEO at pxdraft",
    },
    {
      imageUrl: "https://uiocean.com/themeforest/react/krinky/img/avatar-1.jpg",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      name: "Jennifer Lutheran",
      designation: "CEO at pxdraft",
    },
    {
      imageUrl: "https://uiocean.com/themeforest/react/krinky/img/avatar-1.jpg",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      name: "Jennifer Lutheran",
      designation: "CEO at pxdraft",
    },
    {
      imageUrl: "https://uiocean.com/themeforest/react/krinky/img/avatar-1.jpg",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      name: "Jennifer Lutheran",
      designation: "CEO at pxdraft",
    },
    {
      imageUrl: "https://uiocean.com/themeforest/react/krinky/img/avatar-1.jpg",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      name: "Jennifer Lutheran",
      designation: "CEO at pxdraft",
    },
  ];

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <div className="w-full ">
        <div className="xl:mx-28 pt-10 container !m-auto lg:pt-24 lg:pb-32 pb-20 px-3 xl:px-0 relative">
          <div className="uppercase text-[38px] md:text-[45px] font-semibold text-center tracking-widest ">
            <h3> Testimonial </h3>
          </div>

          <div className="pt-6 lg:pt-12 ">
            <Swiper
              loop={true}
              pagination={{ clickable: true, el: ".swiper-pagination" }}
              spaceBetween={30}
              breakpoints={{
                320: { slidesPerView: 1 },
                640: { slidesPerView: 2 },
                768: { slidesPerView: 2 },
                1024: { slidesPerView: 2 },
              }}
              modules={[Pagination]}
              className="w-full h-full"
            >
              {testimonialData.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="p-[25px] lg:p-[30px] flex flex-col md:flex-row border border-black">
                    <div className="mb-4 md:mb-0">
                      <img
                        className="border border-black md:w-[100%] w-full"
                        src={item.imageUrl}
                        alt={`Testimonial from ${item.name}`}
                      />
                    </div>

                    <div className="pl-5">
                      <p className="text-[#555]">{item.description}</p>
                      <h6 className="font-semibold">{item.name}</h6>
                      <span className="text-[#555]">{item.designation}</span>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="swiper-pagination !relative !mt-5 gap-4 flex justify-center z-10 !bottom-0" />
        </div>
      </div>
    </motion.div>
  );
};
