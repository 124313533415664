import React from "react";
import { Mail, Phone, MapPin } from "lucide-react";
import { FaLinkedinIn } from "react-icons/fa";
import { SecondaryButton } from "@/util/Button/SecondaryButton";
import { InputText } from "@/util/Form/InputText";
import { InputEmail } from "@/util/Form/InputEmail";
import { TextArea } from "@/util/Form/TextArea";
import { FormProvider, useForm } from "react-hook-form";
import { BsWhatsapp } from "react-icons/bs";
import { motion, useInView } from "framer-motion"; // Import motion and useInView
import BackToTop from "@/components/Common/Footer/BacktoTop";

const contactInfo = [
  {
    icon: <Mail className="w-6 h-6" />,
    label: "Mail",
    value: "hr@bronzebyte.com",
    link: "mailto:hr@bronzebyte.com",
    bgColor: "bg-[#fec447]",
  },
  {
    icon: <Phone className="w-6 h-6" />,
    label: "Phone",
    value: "+917710113366",
    bgColor: "bg-[#9bd3d0]",
  },
  {
    icon: <MapPin className="w-6 h-6" />,
    label: "Visit our tech studio",
    value:
      "#205 Flexi Tower, E-288, Industrial Area, Sector 75, Sahibzada Ajit Singh Nagar, Mohali, Punjab 160055, IN",
    link: "https://www.google.com/maps/dir//Industrial+Area,+Sector+75,+Sahibzada+Ajit+Singh+Nagar,+Punjab+140308/@30.699787,76.6087805,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x390feefcce48fbd1:0xca9b3493dbdb74c3!2m2!1d76.6911818!2d30.699813?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D",
    bgColor: "bg-[#feccb5]",
  },
];

export const Footer = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    reset();
  };

  const methods = useForm();

  const phoneNumber = "917710113366";

  const whatsappLink = `https://wa.me/${phoneNumber}`;

  // In view reference
  const ref = React.useRef(null);
  const inView = useInView(ref, { triggerOnce: false, threshold: 0.1 });

  return (
    <>
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.6, ease: "easeOut" }}
        
      >
        <div id="contact" className="bg-[#373737] md:py-[78px] py-[75px]">
          <div className="xl:mx-28 px-3 xl:px-0 container !m-auto grid gap-2 xl:gap-6 lg:grid lg:grid-cols-2">
            <div className="bg-white border border-black p-7">
              <h6 className="font-semibold text-[32px]">Get in touch</h6>
              <p className="text-[#555] text-[18px]">
                Our friendly team would love to hear from you.
              </p>

              <div className="mt-5">
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid md:grid md:grid-cols-2 gap-4 text-left ">
                      <InputText
                        name={"name"}
                        placeholder="Name"
                        label="First name"
                        register={register}
                        errors={errors}
                        options={{ required: "First Name is required." }}
                      />
                      <InputEmail
                        name="email"
                        placeholder="Email"
                        label="Your Email"
                        register={register}
                        errors={errors}
                        options={{
                          required: "Email is required.",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: "Invalid email format.",
                          },
                        }}
                      />
                    </div>

                    <div className="mt-4 text-left">
                      <InputText
                        name="subject"
                        placeholder="subject"
                        label="subject"
                        register={register}
                        errors={errors}
                        options={{ required: "Subject is required." }}
                      />
                      <TextArea
                        name="message"
                        placeholder="Your message"
                        label="Your message"
                        register={register}
                        errors={errors}
                        options={{ required: "Message is required." }}
                      />
                    </div>

                    <SecondaryButton />
                  </form>
                </FormProvider>
              </div>
            </div>

            <div className="lg:ml-40 xl:ml-52">
              <div className="pb-10 font-bold text-[#ffffff] text-2xl">
                BronzeByte IT Solutions
              </div>

              <div className="p-6 rounded-lg">
                <ul className="space-y-4 cursor-pointer">
                  {contactInfo?.map((item, index) => {
                    return (
                      <li key={index} className="flex items-start">
                        <div className={`${item.bgColor} p-3 rounded-lg`}>
                          {item.icon}
                        </div>
                        <div className="ml-4">
                          <h5 className="text-[#fffc] text-sm uppercase tracking-widest text-left">
                            {item?.label}
                          </h5>
                          {item.link ? (
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-white text-lg font-semibold mt-1 text-left"
                            >
                              {item.value}
                            </a>
                          ) : (
                            <p className="text-white text-lg font-semibold mt-1 text-left">
                              {item.value}
                            </p>
                          )}
                        </div>
                      </li>
                    );
                  })}
                  <BackToTop />
                </ul>
              </div>
            </div>
          </div>
        </div>

        <footer className="bg-[#373737] border-t border-[#ffffff1a] p-4 xl:px-2">
          <div className="xl:mx-28 px-3 xl:px-0 text-center md:flex md:justify-between container !m-auto md:items-center">
            <div className="flex justify-center gap-5 text-[#ffffffbf]">
              <a
                href="https://www.linkedin.com/company/bronzebyte-it-solutions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn className="text-blue-600 text-3xl" />
              </a>

              <a
                href={whatsappLink}
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer"
              >
                <BsWhatsapp className="text-green-600 text-3xl" />
              </a>
            </div>

            <div className="pt-3 md:pt-0 text-base text-[#ffffffbf]">
              <p>© {new Date().getFullYear()} copyright all rights reserved</p>
            </div>
          </div>
        </footer>
      </motion.div>
    </>
  );
};
