export const SecondaryButton = () => {
    return (
        <button
            type="submit"
            className={`mt-6 text-sm tracking-widest bg-tertiary hover:bg-primary px-5 py-[7px] shadow-[3px_3px] shadow-primary hover:shadow-[#fec448] hover:text-white uppercase border border-[#fec448]`}
        >
            Send Message
        </button>
    )
}

