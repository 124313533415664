import React from "react";
import { motion, useInView } from "framer-motion";
import aboutBanner from "@/assets/images/rear-view-programmer-working-all-night-long.jpg";

export const ProfileOverview = () => {
  const ref = React.useRef(null);
  const inView = useInView(ref, { triggerOnce: false, threshold: 0.1 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <div className="container mx-auto px-4 py-12 lg:py-28 grid gap-9 lg:grid-cols-2 lg:gap-6">
        <div>
          <div>
            <img
              src={aboutBanner}
              alt="About us banner"
              className="w-full h-auto"
            />
          </div>
        </div>

        <div>
          <div>
            <h3 className="mb-5 font-semibold text-2xl lg:text-4xl leading-tight text-[#373737]">
              Your Trusted Partner in Web Development
            </h3>
            <p className="mb-5 text-lg lg:text-base font-normal leading-tight text-[#555]">
              With over 3 years of experience in the web development
              industry, we specialize in crafting exceptional digital
              solutions. Based in San Francisco, our team is dedicated to
              delivering high-quality web applications and design that meet
              the unique needs of our clients worldwide. We thrive on
              collaboration, bringing innovative ideas to life through
              creative partnerships.
            </p>
          </div>

          <div className="flex gap-6">
            <div>
              <h5 className="text-2xl font-semibold text-primary">285+</h5>
              <span className="text-base font-semibold">
                Projects Delivered
              </span>
            </div>

            <div className="px-2">
              <h5 className="text-2xl font-semibold text-primary">190+</h5>
              <span className="text-base font-semibold">
                Satisfied Clients
              </span>
            </div>
          </div>

          <div className="pt-6 flex gap-6">
            <a
              href="#contact"
              className="px-5 py-2 bg-primary text-sm font-medium tracking-widest w-40 shadow-lg shadow-secondary border border-primary uppercase text-white hover:shadow-sm hover:bg-secondary hover:border-secondary"
            >
              Get in Touch
            </a>
            <a
              href="#portfolio"
              className="px-5 py-2 bg-[#373737] text-sm font-medium tracking-widest w-36 shadow-lg shadow-primary border border-secondary uppercase text-white hover:shadow-sm hover:bg-primary hover:border-primary"
            >
              Our Work
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
