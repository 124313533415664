import "swiper/css";
import './index.css'

import { HomeBanner } from "@/components/HomeBanner/HomeBanner";
import { LogoSection } from "@/components/LogoSection";
import { OurSkills } from "@/components/OurSkills/OurSkills";
import { LatestProject } from "@/components/LatestProject/LatestProject";
import { TestiMonial } from "@/components/Testimonial/Testimonial";
import { OurServices } from "@/components/OurServices/OurServices";
import { Layout } from "@/components/Common/Layout/Layout";
import { ProfileOverview } from "@/components/ProfileOverview";
function App() {
  return (
    <div className="App">
      <Layout>
        <HomeBanner />
        <LogoSection />
        <ProfileOverview />
        <OurServices />
        <OurSkills />
        <LatestProject />
        <TestiMonial />
      </Layout>
    </div>
  );
}

export default App;
